import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { UserActions } from '../user/user.actions';

export const authFeatureKey = 'auth';

export interface State {
  status: string,
  message: string,
}

export const initialState: State = {
  status: 'pending',
  message: ''
};

export const reducer = createReducer(
  initialState,
  on(
    AuthActions.loginSuccess,
    (state, action) => {
      localStorage.setItem('token', action.token);
      UserActions.loadUser();
      return {
        ...state,
        status: 'success'
      }
    }
  ),
  on(
    AuthActions.loginFailure,
    (state, action) => {
      return {
        ...state,
        status: 'error',
        message: action.error.error.message
      }
    }
  ),
  on(
    AuthActions.registerFailure,
    (state, action) => {
      return {
        ...state,
        status: 'error',
        message: action.error.error.message
      }
    }
  ),
  on(
    AuthActions.registerSuccess,
    (state, action) => {
      localStorage.setItem('token', action.token);
      UserActions.loadUser();
      return {
        ...state,
        status: 'success'
      }
    }
  ),
  on(
    AuthActions.logout,
    (state, action) => {
      return {
        ...state,
        status: 'pending'
      }
    }
  )
);

