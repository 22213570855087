import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter, map, shareReplay } from 'rxjs/operators';
import { FirebaseCloudMessagingService } from './core/services/firebase-cloud-messaging.service';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.store';
import { BrandActions } from './store/catalog/brands/brand.actions';
import { HomeActions } from './store/home/home.actions';
import { CategoryActions } from './store/catalog/categories/category.actions';
import { UserActions } from './store/user/user.actions';
import { CartActions } from './store/cart/cart.actions';
import { WishlistActions } from './store/wishlist/wishlist.actions';
import { OrderActions } from './store/order/order.actions';
import { AddressActions } from './store/address/address.actions';
import { getUserStatus } from './store/user/user.selectors';
import { getHomeData } from './store/home/home.selectors';
import { home } from './core/interface/home';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { QuoteActions } from './store/quotes/quote.actions';
declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isHandset$: Observable<boolean> = this._breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  homeData$: Observable<home> = EMPTY;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    // private _FCM: FirebaseCloudMessagingService,
    private _store: Store<AppState>,
    private _swUpdate: SwUpdate
  ) {}

  ngOnInit() {
    // this._FCM.startFirbaseCloudMessaging();
    this.loadAllData();
    this.homeData$ = this._store.select(getHomeData);
    this.swUpdate();
  }

  loadAllData() {
    this._store.dispatch(HomeActions.loadHome());
    this._store.dispatch(BrandActions.loadBrands());
    this._store.dispatch(CategoryActions.loadCategories());
    this._store.dispatch(UserActions.loadUser());
    this._store.dispatch(QuoteActions.loadQuotes());
    this._store.select(getUserStatus).subscribe((status) => {
      if (status === 'loggedIn') {
        this._store.dispatch(AddressActions.loadCities());
        this._store.dispatch(CartActions.loadCart());
        this._store.dispatch(WishlistActions.loadWishlist());
        this._store.dispatch(OrderActions.loadOrders());
        this._store.dispatch(AddressActions.loadAddress());
        this._store.dispatch(CartActions.loadSelectableGifts());
      }
    });
  }

  async swUpdate() {
    try {
      const updateFound = await this._swUpdate.checkForUpdate();
      if (updateFound) {
        this._swUpdate.versionUpdates
          .pipe(
            filter(
              (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
            )
          )
          .subscribe(() => {
            if (confirm('New Version Available! Update?')) {
              document.location.reload();
            }
          });
      }
    } catch (err) {
      console.error('Failed to check for updates:', err);
    }
  }
}
