import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from './cart.actions';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.store';
import { getCartParam } from './cart.selectors';
import { NoticeService } from 'src/app/core/services/notice.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class CartEffects {


  constructor(
    private actions$: Actions,
    private _api: ApiHttpService,
    private _store: Store<AppState>,
    private _notice: NoticeService,
    private _payment: PaymentService
  ) { }

  loadCart$ = createEffect(
    () => this.actions$.pipe(
      ofType(CartActions.loadCart),
      exhaustMap(
        action => this._api.get(API_ROUTES.getCart).pipe(
          map((data: any) => CartActions.loadCartSuccess({ data: data.data })),
          catchError(err => of(CartActions.loadCartFailure({ error: err })))
        )
      )
    )
  )

  updateCart$ = createEffect(
    () => this.actions$.pipe(
      ofType(CartActions.reloadCart, CartActions.updateCart),
      exhaustMap(
        action => {
          let params;
          this._store.select(getCartParam).subscribe(
            res => params = res
          );
          return this._api.post(API_ROUTES.updateCart, params).pipe(
            map((data: any) => CartActions.loadCartSuccess({ data: data.data })),
            catchError(err => of(CartActions.loadCartFailure({ error: err })))
          )
        }
      )
    )
  )


  reloadSelectableGifts$ = createEffect(
    () => this.actions$.pipe(
      ofType(CartActions.reloadCart),
      map(() => CartActions.loadSelectableGifts())
    )
  )

  addToCart$ = createEffect(
    () => this.actions$.pipe(
      ofType(CartActions.addToCart),
      exhaustMap(
        action => {
          let params: any = { attribute_product_id: action.id, quantity: action.quantity, is_cart: 1 };
          if (localStorage.getItem('affiliate_type') && localStorage.getItem('affiliate_id')) {
            params = {
              ...params,
              affiliation_type: localStorage.getItem('affiliate_type'),
              affiliation_id: localStorage.getItem('affiliate_id')
            }
          }
          return this._api.post(API_ROUTES.addToCart, params).pipe(
            map((data: any) => {
              const action = { hasAction: true, text: 'Go To Cart', route: '/cart' };
              this._notice.addNotice(data.message, 'success', 5000, action);
              return CartActions.reloadCart();
            }),
            catchError(err => {
              const action = { hasAction: true, text: 'Go To Cart', route: '/cart' };
              this._notice.addNotice(err.error.message, 'error', 5000, action)
              return of(CartActions.loadCartFailure({ error: err }))
            })
          )
        }
      )
    )
  )

  paymentMethods$ = createEffect(
    () => this.actions$.pipe(
      ofType(CartActions.loadPayments),
      exhaustMap(
        action => this._api.get(API_ROUTES.paymentGateways).pipe(
          map((data: any) => CartActions.loadPaymentsSuccess({ data: data.data })),
          catchError(err => of())
        )
      )
    )
  )

  checkOut$ = createEffect(
    () => this.actions$.pipe(
      ofType(CartActions.checkout),
      exhaustMap(
        action => {
          let params;
          this._store.select(getCartParam).subscribe(
            res => params = res
          )
          return this._api.post(API_ROUTES.checkout, params).pipe(
            map((data: any) => {
              CartActions.reloadCart();
              this._payment.performPayment(data.data.payment_gateway, data.data.billing_amount, data.data.order_id, data.data.amount_payable);
              return CartActions.checkoutSuccess();
            }),
            catchError(err => {
              this._notice.addNotice(err.error.message, 'error');
              return of();
            })
          )
        }
      )
    )
  )

  loadGifts$ = createEffect(
    () => this.actions$.pipe(
      ofType(CartActions.loadSelectableGifts),
      exhaustMap(
        () => this._api.get(API_ROUTES.selectableGiftList).pipe(
          map((data: any) => CartActions.loadSelectableGiftsSuccess({ data: data.data }))
        )
      )
    )
  )
}
