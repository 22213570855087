import { createReducer, on } from '@ngrx/store';
import { ReviewActions } from './review.actions';

export const reviewFeatureKey = 'review';

export interface State {
  productReviews: any,
  userReviews: any,
  status: string,
}

export const initialState: State = {
  productReviews: null,
  userReviews: null,
  status: 'pending',
};

export const reducer = createReducer(
  initialState,
  on(
    ReviewActions.loadUserReviewsSuccess,
    (state, action) => {
      return {
        ...state,
        userReviews: action.data
      }
    }
  ),
  on(
    ReviewActions.loadProductReviews,
    (state, action) => {
      return {
        ...state,
        status: 'loading'
      }
    }
  ),
  on(
    ReviewActions.loadProductReviewsSuccess,
    (state, action) => {
      return {
        ...state,
        status: 'success',
        productReviews: action.data
      }
    }
  )
);

