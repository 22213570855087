<div class="bottom_navbarsecs_mbl">
    <div class="wrap_bottom_navbars">
        <div class="toolbar">
            <a class="nav" [routerLink]="['/']" [routerLinkActive]="'activeNav'"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="nav-icon">
                    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13.6078L13.5 1L26 13.6078" stroke="currentColor" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M3.77783 10.8062V20.6122C3.77783 21.3553 4.07049 22.0679 4.59142 22.5933C5.11236 23.1187 5.8189 23.4139 6.55561 23.4139H20.4445C21.1812 23.4139 21.8877 23.1187 22.4087 22.5933C22.9296 22.0679 23.2223 21.3553 23.2223 20.6122V10.8062"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M3.96289 10.7126L13.2221 1.37354L22.8518 10.7126C22.9752 12.0823 23.2221 15.4942 23.2221 18.1839C23.2221 20.8735 22.2345 22.5421 21.7407 23.0402C18.5308 23.2892 11.4444 23.7126 8.77771 23.4138C6.11104 23.1149 4.45672 21.795 3.96289 21.1724V10.7126Z"
                            fill="currentColor" stroke="currentColor" />
                    </svg>
                </div>
                <span>Home</span>
            </a>

            <a class="nav" [routerLink]="['/catalog']" [routerLinkActive]="'activeNav'"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="nav-icon">
                    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.395 9.80824C21.4469 9.80824 23.1102 8.14486 23.1102 6.09296C23.1102 4.04107 21.4469 2.37769 19.395 2.37769C17.3431 2.37769 15.6797 4.04107 15.6797 6.09296C15.6797 8.14486 17.3431 9.80824 19.395 9.80824Z"
                            stroke="currentColor" stroke-opacity="0.71" stroke-width="1.4" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M7.01069 22.1925C9.06258 22.1925 10.726 20.5291 10.726 18.4772C10.726 16.4253 9.06258 14.762 7.01069 14.762C4.9588 14.762 3.29541 16.4253 3.29541 18.4772C3.29541 20.5291 4.9588 22.1925 7.01069 22.1925Z"
                            stroke="currentColor" stroke-opacity="0.71" stroke-width="1.4" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M15.6797 14.7619H23.1102V20.9541C23.1102 21.2825 22.9797 21.5975 22.7475 21.8298C22.5152 22.062 22.2002 22.1925 21.8718 22.1925H16.9181C16.5896 22.1925 16.2746 22.062 16.0424 21.8298C15.8101 21.5975 15.6797 21.2825 15.6797 20.9541V14.7619ZM3.29541 2.37769H10.726V8.56982C10.726 8.89827 10.5955 9.21327 10.3632 9.44552C10.131 9.67777 9.81599 9.80824 9.48754 9.80824H4.53384C4.20538 9.80824 3.89039 9.67777 3.65814 9.44552C3.42589 9.21327 3.29541 8.89827 3.29541 8.56982V2.37769Z"
                            stroke="currentColor" stroke-opacity="0.71" stroke-width="1.4" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    
                        
                </div>
                <span>Categories</span>
            </a>
            <a class="nav" [routerLink]="['/cart']" [routerLinkActive]="'activeNav'"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="nav-icon">
                    <svg width="18" height="26" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 6H17L16.165 15.181C16.1198 15.6779 15.8906 16.14 15.5222 16.4766C15.1538 16.8131 14.673 16.9998 14.174 17H3.826C3.32704 16.9998 2.84617 16.8131 2.4778 16.4766C2.10942 16.14 1.88016 15.6779 1.835 15.181L1 6Z" stroke="currentColor" stroke-linejoin="round"/>
                        <path d="M5 8V5C5 3.93913 5.42143 2.92172 6.17157 2.17157C6.92172 1.42143 7.93913 1 9 1C10.0609 1 11.0783 1.42143 11.8284 2.17157C12.5786 2.92172 13 3.93913 13 5V8" stroke="currentColor" stroke-linecap="round"/>
                    </svg>
                        
                        
                </div>
                <span>Cart</span>
            </a>
            
            <a class="nav" [routerLink]="['/profile']" [routerLinkActive]="'activeNav'"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="nav-icon">
                    <svg width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Nav Icons">
                            <path id="Vector"
                                d="M31.5104 24.1237C29.5753 20.8919 26.5374 18.6242 23.0074 17.6624C24.7236 16.8193 26.096 15.4421 26.9032 13.7529C27.7105 12.0636 27.9056 10.1609 27.457 8.35144C27.0085 6.54196 25.9425 4.93123 24.4309 3.77894C22.9193 2.62666 21.0502 2 19.125 2C17.1998 2 15.3307 2.62666 13.8191 3.77894C12.3075 4.93123 11.2415 6.54196 10.793 8.35144C10.3444 10.1609 10.5395 12.0636 11.3468 13.7529C12.154 15.4421 13.5264 16.8193 15.2426 17.6624C11.7126 18.623 8.67473 20.8907 6.73963 24.1237C6.68649 24.2045 6.65081 24.2949 6.63474 24.3893C6.61866 24.4838 6.62252 24.5804 6.64609 24.6734C6.66965 24.7664 6.71243 24.8538 6.77185 24.9303C6.83128 25.0069 6.90611 25.0711 6.99187 25.119C7.07762 25.1669 7.17251 25.1976 7.27083 25.2091C7.36915 25.2206 7.46887 25.2129 7.564 25.1862C7.65912 25.1595 7.74768 25.1145 7.82434 25.0539C7.90101 24.9933 7.9642 24.9183 8.01011 24.8335C10.3614 20.9073 14.5155 18.5638 19.125 18.5638C23.7345 18.5638 27.8886 20.9073 30.2399 24.8335C30.2858 24.9183 30.349 24.9933 30.4257 25.0539C30.5023 25.1145 30.5909 25.1595 30.686 25.1862C30.7811 25.2129 30.8809 25.2206 30.9792 25.2091C31.0775 25.1976 31.1724 25.1669 31.2581 25.119C31.3439 25.0711 31.4187 25.0069 31.4781 24.9303C31.5376 24.8538 31.5804 24.7664 31.6039 24.6734C31.6275 24.5804 31.6313 24.4838 31.6153 24.3893C31.5992 24.2949 31.5635 24.2045 31.5104 24.1237ZM12.026 10.2831C12.026 8.92613 12.4423 7.5996 13.2224 6.47129C14.0024 5.34298 15.1111 4.46357 16.4083 3.94427C17.7055 3.42496 19.1329 3.28909 20.51 3.55383C21.887 3.81857 23.152 4.47203 24.1448 5.43158C25.1376 6.39112 25.8137 7.61366 26.0876 8.9446C26.3616 10.2755 26.221 11.6551 25.6837 12.9088C25.1463 14.1625 24.2364 15.2341 23.069 15.988C21.9016 16.7419 20.5291 17.1443 19.125 17.1443C17.2429 17.1421 15.4386 16.4185 14.1077 15.1323C12.7769 13.846 12.0282 12.1022 12.026 10.2831Z"
                                fill="currentColor" fill-opacity="0.71" />
                        </g>
                    </svg>
                </div>
                <span>Profile</span>
            </a>
        </div>
    </div>

</div>