import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, map, tap } from 'rxjs';
import { ApiHttpService } from '../../services/api-http.service';
import { API_ROUTES } from '../../constants/api.constants';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  order_id = 0;
  orderData$: Observable<any> = EMPTY;

  constructor (
    private _activeRoute: ActivatedRoute,
    private _api: ApiHttpService
  ) {}

  ngOnInit() {
    const sub = this._activeRoute.params.subscribe(
      (param: any) => {
        this.order_id = param.order_id;
        this.orderData$ = this._api.get(API_ROUTES.order, param).pipe(
          tap((data: any) => {
              window.fbq('track', 'Purchase', { value: data.data.billing_amount, currency: 'NPR', content_type: 'Product', content_ids: ['\''+param.order_id+'\''] });
          }),
          map((data: any) => data.data));
        sub.unsubscribe();
      }
    );

  }
}
