
<div class="faq_container_secstions">
    <div class="insidepage_bannersecstions">
        <img loading="lazy"  src="../../../../assets/images/login_banner.jpg" alt="Buy Makeup Product Online | Foreveryng">
    </div>
    <div class="container">
        <div class="wrap_faqsections">
            <div class="shipping_policy_container main_content_sectionss">
               <h1>Shipping Policy</h1>
                <div class="wrap_shipping_policies">
                    <div class="blocks_policies block_policies_full">
                        <h3>How does the delivery process work?</h3>
                        <ul>
                            <li>
                                Once you place an order, we carefully check and inspect the products to ensure they are in perfect
                                condition. Once they pass the quality checks, we pack them securely to protect them during
                                transportation.
                            </li>
                            <li>
                                Once packed, your order will be sent from our warehouse to our delivery partner. You'll receive a
                                notification confirming that your order has been dispatched, keeping you updated on its progress.
                            </li>
                            <li>
                                Our trusted delivery partner takes charge of the package and ensures it is safely delivered to your
                                provided address. They strive to deliver it quickly, but if they encounter any issues, they will
                                promptly contact you to resolve them.
                            </li>
                        </ul>
                    </div>

                    <div class="blocks_policies">
                        <h3>How are items packaged?</h3>
                        <p>
                            We package our products in boxes covered with a plastic layer. Each product is individually wrapped in
                            bubble wrap, and fragile items like bottles are additionally secured with bubble wrap. We take pride in
                            the quality of our packaging.
                        </p>
                    </div>

                    <div class="blocks_policies">
                        <h3>What is the range of locations where Foreveryng ships its products?</h3>
                        <p>
                            Foreveryng offers shipping services throughout Nepal. Our goal is to make our products accessible to
                            customers all over the country, regardless of their location. From major cities to remote areas, we aim
                            to reach every corner of Nepal with our shipping services.
                        </p>
                    </div>

                    <div class="blocks_policies">
                        <h3>What is the estimated delivery time?</h3>
                        <p>
                            We usually dispatch most orders within 1-3 business days (excluding Saturdays and public holidays).
                            While 90% of our catalogue is in stock, certain products may need to be sourced directly from the brand,
                            causing a delay. If you order during a Mega Sale Campaign, dispatches may take up to 5 days. We strive
                            to deliver your order as quickly as possible.
                        </p>
                    </div>

                    <div class="blocks_policies">
                        <h3>Will my order be shipped in multiple shipments?</h3>
                        <p>
                            No, we try to streamline our shipping process to deliver your order in a single shipment whenever
                            possible. We understand the importance of convenience and efficiency, and consolidating items into one
                            shipment helps us achieve that goal.
                        </p>
                    </div>

                    <div class="blocks_policies">
                        <h3>Are there any shipping charges applicable to my order?</h3>
                        <p>
                            We have standard shipping charges for most addresses. For deliveries outside the valley below Rs.1000,
                            there is a fixed shipping charge of Rs.100/-. For deliveries outside the valley above Rs.1000, the
                            shipping charge is Rs.79/- per delivery. For deliveries inside the valley below Rs.1000, there will be a
                            shipping charge of Rs.50/-. However, for orders above Rs.1000, we offer free delivery within the valley.
                        </p>
                    </div>
                    
                    <div class="blocks_policies">
                        <h3>Does Foreveryng ship outside Nepal?</h3>
                        <p>
                            Currently, Foreveryng only ships within Nepal. We do not offer international shipping services. However,
                            we are working on expanding our reach and exploring options for international delivery to serve
                            customers in other countries. We appreciate your support and encourage you to stay tuned for updates
                            regarding international shipping availability.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   
</div>

