import * as Home from "./home/home.reducer";
import { HomeEffects } from "./home/home.effects";
import * as Brand from "./catalog/brands/brand.reducer";
import { BrandEffects } from "./catalog/brands/brand.effects";
import * as Category from "./catalog/categories/category.reducer";
import { CategoryEffects } from "./catalog/categories/category.effects";
import * as Auth from "./auth/auth.reducer";
import { AuthEffects } from "./auth/auth.effects";
import * as User from "./user/user.reducer";
import { UserEffects } from "./user/user.effects";
import * as Cart from "./cart/cart.reducer";
import { CartEffects } from "./cart/cart.effects";
import * as Filter from "./filter/filter.reducer";
import { FilterEffects } from "./filter/filter.effects";
import * as Wishlist from "./wishlist/wishlist.reducer";
import { WishlistEffects } from "./wishlist/wishlist.effects";
import * as Product from "./product/product.reducer";
import { ProductEffects } from "./product/product.effects";
import * as Review from "./review/review.reducer";
import { ReviewEffects } from "./review/review.effects";
import * as Order from "./order/order.reducer";
import { OrderEffects } from "./order/order.effects";
import * as Address from "./address/address.reducer";
import { AddressEffects } from "./address/address.effects";
import * as Post from "./posts/post.reducer";
import { PostEffects } from "./posts/post.effects";
import * as Quotes from "./quotes/quote.reducer";
import { QuoteEffects } from "./quotes/quote.effects";

export interface AppState {
    [Home.homeFeatureKey]: Home.State,
    [Brand.brandFeatureKey]: Brand.State,
    [Category.categoryFeatureKey]: Category.State,
    [Auth.authFeatureKey]: Auth.State,
    [User.userFeatureKey]: User.State,
    [Wishlist.wishlistFeatureKey]: Wishlist.State,
    [Cart.cartFeatureKey]: Cart.State,
    [Filter.filterFeatureKey]: Filter.State,
    [Product.productFeatureKey]: Product.State,
    [Review.reviewFeatureKey]: Review.State,
    [Order.orderFeatureKey]: Order.State,
    [Address.addressFeatureKey]: Address.State,
    [Post.postFeatureKey]: Post.State,
    [Quotes.quoteFeatureKey]: Quotes.State,
}

export const AppReducer = {
    [Home.homeFeatureKey]: Home.reducer,
    [Brand.brandFeatureKey]: Brand.reducer,
    [Category.categoryFeatureKey]: Category.reducer,
    [Auth.authFeatureKey]: Auth.reducer,
    [User.userFeatureKey]: User.reducer,
    [Cart.cartFeatureKey]: Cart.reducer,
    [Wishlist.wishlistFeatureKey]: Wishlist.reducer,
    [Filter.filterFeatureKey]: Filter.reducer,
    [Product.productFeatureKey]: Product.reducer,
    [Review.reviewFeatureKey]: Review.reducer,
    [Order.orderFeatureKey]: Order.reducer,
    [Address.addressFeatureKey]: Address.reducer,
    [Post.postFeatureKey]: Post.reducer,
    [Quotes.quoteFeatureKey]: Quotes.reducer,
}

export const AppEffect = [
    HomeEffects,
    BrandEffects,
    CategoryEffects,
    AuthEffects,
    UserEffects,
    CartEffects,
    FilterEffects,
    WishlistEffects,
    ProductEffects,
    ReviewEffects,
    OrderEffects,
    AddressEffects,
    PostEffects,
    QuoteEffects,
];