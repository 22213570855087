import { createReducer, on } from '@ngrx/store';
import { PostActions } from './post.actions';

export const postFeatureKey = 'post';

export interface State {
  data: any
}

export const initialState: State = {
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    PostActions.loadPostsSuccess,
    (state, action) => {
      return {
        ...state,
        data: action.data
      }
    }
  )
);

