import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HomeActions } from './home.actions';
import { exhaustMap, map } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class HomeEffects {
  constructor(
    private actions$: Actions,
    private _api: ApiHttpService
  ) { }

  loadHomeData$ = createEffect(
    () => this.actions$.pipe(
      ofType(HomeActions.loadHome),
      exhaustMap(
        () => this._api.get(API_ROUTES.home).pipe(
          map((data: any) => HomeActions.homeDataLoadSuccess({ data: data.data })),
        )
      )
    )
  )

  loadHandsetHomeBanners$ = createEffect(
    () => this.actions$.pipe(
      ofType(HomeActions.loadHome),
      exhaustMap(
        () => this._api.get(API_ROUTES.homeBanners).pipe(
          map((data: any) => HomeActions.homeBannersLoadSuccess({ data: data.data })),
        )
      )
    )
  )

  loadHomeBrands$ = createEffect(
    () => this.actions$.pipe(
      ofType(HomeActions.loadHome),
      exhaustMap(
        () => this._api.get(API_ROUTES.homeBrands).pipe(
          map((data: any) => HomeActions.homeBrandsLoadSuccess({ data: data.data })),
        )
      )
    )
  )

  loadHomeProducts$ = createEffect(
    () => this.actions$.pipe(
      ofType(HomeActions.loadHome),
      exhaustMap(
        () => this._api.get(API_ROUTES.homeProducts).pipe(
          map((data: any) => HomeActions.homeProductsLoadSuccess({ data: data.data })),
        )
      )
    )
  )

  loadHomeCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(HomeActions.loadHome),
      exhaustMap(
        () => this._api.get(API_ROUTES.homeCategories).pipe(
          map((data: any) => HomeActions.homeCategoriesLoadSuccess({ data: data.data })),
        )
      )
    )
  )

}
