import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from './auth.actions';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserActions } from '../user/user.actions';
import { Router } from '@angular/router';
import { API_ROUTES } from 'src/app/core/constants/api.constants';
import { CartActions } from '../cart/cart.actions';
import { WishlistActions } from '../wishlist/wishlist.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.store';



@Injectable()
export class AuthEffects {


  constructor(
    private actions$: Actions,
    private _api: ApiHttpService,
    private _auth: AuthService,
    private _router: Router,
    private _store: Store<AppState>
  ) { }

  loginAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.attemptLogin),
        exhaustMap(
          (action: any) =>
            this._api.post(API_ROUTES.loginUser, action.params).pipe(
              map((data: any) => {
                return AuthActions.loginSuccess({ token: data.data.token })
              }),
              catchError(err => of(AuthActions.loginFailure({ error: err })))
            )
        )
      )
  )

  registerAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.attemptRegister),
        exhaustMap(
          (action: any) =>
            this._api.post(API_ROUTES.registerUser, action.params).pipe(
              map((data: any) => AuthActions.registerSuccess({ token: data.data.token })),
              catchError(err => of(AuthActions.registerFailure({ error: err })))
            )
        )
      )
  )

  logoutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        exhaustMap(
          () => this._api.post(API_ROUTES.logoutUser).pipe(
            map(() => {
              this._auth.removeToken();
              this._router.navigate(['/']);
              this._store.dispatch(CartActions.removeCartData());
              this._store.dispatch(WishlistActions.removeWishlistData());
              return UserActions.removeUserData();
            }),
            catchError(err => of(AuthActions.logoutFailure()))
          )
        )
      )
  )
}
