import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FilterActions } from './filter.actions';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.store';
import { getFilterParams, proceedFilterUpdate } from './filter.selectors';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class FilterEffects {


  constructor(
    private actions$: Actions,
    private _api: ApiHttpService,
    private _store: Store<AppState>
  ) { }


  $loadFilterData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterActions.setNewParams, FilterActions.updateParams),
        exhaustMap(
          () => {
            let parameters;
            this._store.select(getFilterParams).subscribe(
              params => parameters = {...params }
            )
            return this._api.get(API_ROUTES.filter, parameters).pipe(
              map((data: any) => FilterActions.setNewData({ data: data.data })),
              catchError(err => of(err))
            )
          }
        )
      )
  )
}
