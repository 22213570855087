import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { QuoteActions } from './quote.actions';
import { exhaustMap, map } from 'rxjs';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class QuoteEffects {


  constructor(private actions$: Actions, private _api: ApiHttpService) {}

  $loadQuotes$ = createEffect(
    () => this.actions$.pipe(
      ofType(QuoteActions.loadQuotes),
      exhaustMap(
        () => this._api.get(API_ROUTES.getQuotes).pipe(
          map((res: any) => QuoteActions.loadQuotesSuccess({ data: res.data}))
        )
      )
    )
  )
}
