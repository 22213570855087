import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddressActions } from './address.actions';
import { exhaustMap, map } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class AddressEffects {


  constructor(
    private actions$: Actions,
    private _api: ApiHttpService
    ) {}

  loadAddress$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AddressActions.loadAddress),
        exhaustMap(
          (action: any) => this._api.get(API_ROUTES.addressList).pipe(
            map((data: any) => AddressActions.loadAddressSuccess({ data: data.data }))
          )
        )
      )
    }
  )

  addAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(AddressActions.addAddress),
      exhaustMap(
        (action: any) => this._api.post(API_ROUTES.addAddress, action.params).pipe(
          map(() => AddressActions.loadAddress())
        )
      )
    )
  )

  editAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(AddressActions.editAddress),
      exhaustMap(
        (action: any) => this._api.post(API_ROUTES.updateAddress, action.params).pipe(
          map(() => AddressActions.loadAddress())
        )
      )
    )
  )

  deleteAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(AddressActions.deleteAddress),
      exhaustMap(
        (action: any) => this._api.post(API_ROUTES.deleteAddress, { delivery_address_id: action.delivery_address_id }).pipe(
          map(() => AddressActions.loadAddress())
        )
      )
    )
  )

  loadCities$ = createEffect(
    () => this.actions$.pipe(
      ofType(AddressActions.loadCities),
      exhaustMap(
        () => this._api.get(API_ROUTES.cities).pipe(
          map((data: any) => AddressActions.loadCitiesSuccess({ data: data.data }))
        )
      )
    )
  )
}
