import { createReducer, on } from '@ngrx/store';
import { ProductActions } from './product.actions';

export const productFeatureKey = 'product';

export interface State {
  data: any,
  status: string
}

export const initialState: State = {
  data: null,
  status : 'pending'
};

export const reducer = createReducer(
  initialState,
  on(
    ProductActions.loadProductDetail,
    (state, action) => {
      return {
        ...state,
        status: 'loading'
      }
    }
  ),
  on(
    ProductActions.loadProductSuccess,
    (state, action) => {
      return {
        ...state,
        status: 'success',
        data: action.data
      }
    }
  ),
  on(
    ProductActions.dumpProductDetail,
    (state, action) => {
      return {
        ...state,
        data: null
      }
    }
  )
);

