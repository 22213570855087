import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { BottomNavComponent } from './core/components/bottom-nav/bottom-nav.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppEffect, AppReducer } from './store/app.store';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TermsAndConditionsComponent } from './core/components/terms-and-conditions/terms-and-conditions.component';
import { ContactComponent } from './core/components/contact/contact.component';
import { FAQsComponent } from './core/components/faqs/faqs.component';
import { SharedModule } from './shared/shared.module';
import { SearchModule } from './features/search/search.module';
import { PrivacyComponent } from './core/components/privacy/privacy.component';
import { ThankYouComponent } from './core/components/thank-you/thank-you.component';
import { AboutUsComponent } from './core/components/about-us/about-us.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { ContactUsComponent } from './core/components/contact-us/contact-us.component';
import { ShippingPolicyComponent } from './core/components/shipping-policy/shipping-policy.component';
import { MessageBoardComponent } from './core/components/message-board/message-board.component';
import { FormsModule } from '@angular/forms';
import { HomeModule } from './features/home/home.module';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { ResolveDynamicLinkComponent } from './core/components/resolve-dynamic-link/resolve-dynamic-link.component';

@NgModule({ declarations: [
        AppComponent,
        NavbarComponent,
        BottomNavComponent,
        TermsAndConditionsComponent,
        ContactComponent,
        FAQsComponent,
        PrivacyComponent,
        ThankYouComponent,
        AboutUsComponent,
        NotFoundComponent,
        ContactUsComponent,
        ShippingPolicyComponent,
        MessageBoardComponent,
        ResolveDynamicLinkComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        HomeModule,
        SearchModule,
        SharedModule,
        MaterialModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 3 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:3000',
            scope: './'
        }),
        StoreModule.forRoot(AppReducer),
        EffectsModule.forRoot(AppEffect),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideClientHydration()
    ] })
export class AppModule { }
