<div class="faq_container_secstions">
    <div class="insidepage_bannersecstions">
        <img loading="lazy"  src="../../../../assets/images/login_banner.jpg" alt="Buy Makeup Product Online | Foreveryng">
    </div>
    <div class="container">
        <div class="wrap_faqsections">
            <h2>FAQ's (Frequently Asked Questions)</h2>
            <div class="faq_content">
                <div class="question_answer" *ngFor="let qa of faq$ | async; index as i" >
                    <div class="question" tabindex="0" (click)="showAnswer[i] = !showAnswer[i]" (keypress)="showAnswer[i] = !showAnswer[i]" [ngClass]="showAnswer[i] ? 'active' : ''">
                        {{qa.question}}
                    </div>
                    <div class="answer" [ngStyle]="{ 'display': showAnswer[i] ? '' : 'none' }">
                        <div [innerHTML]="qa.answer"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   
</div>