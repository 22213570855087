import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from '../../services/api-http.service';
import { EMPTY, Observable } from 'rxjs';
import { API_ROUTES } from '../../constants/api.constants';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  tcData$: Observable<{ data: string }> = EMPTY;

  constructor (
    private _api: ApiHttpService
  ) {}

  ngOnInit() {
    this.tcData$ = this._api.get(API_ROUTES.termsAndConditons) as Observable<{ data: string }>;
  }
}
