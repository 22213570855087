import { Component, DoCheck } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs';
import { NoticeService } from '../../services/notice.service';
import { Router } from '@angular/router';

interface message { message: string, type: 'success' | 'error' | 'info' | 'warning', time: number, action: { hasAction: boolean, text: string, route: string } }

@Component({
  selector: 'app-message-board',
  templateUrl: './message-board.component.html',
  styleUrls: ['./message-board.component.scss']
})
export class MessageBoardComponent implements DoCheck {
  newMessages: message[] = [];
  isHandSet$ = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  constructor(
    private _notice: NoticeService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router
  ) { }

  ngDoCheck() {
    this.newMessages = [
      ...this.newMessages,
      ...this._notice.getNotices()
    ];

    if (this.newMessages.length) {
      this.newMessages.forEach(
        message => this.viewMessage(message)
      )
      this.newMessages = [];
    }
  }

  viewMessage(message: message) {
    const messageBoard = document.getElementById('message-board') as HTMLElement;
    const messageBox = document.createElement('div');
    messageBox.className = 'message';
    const iconContainer = document.createElement('div');
    iconContainer.className = 'icon';
    iconContainer.innerHTML = `
      <div class="icon">
        <img loading="lazy"  src="../../../../assets/icons/${message.type}.svg" alt="Buy Makeup Product Online | Foreveryng" width="42px" height="42px">
      </div>
    `;
    const messageContainer = document.createElement('div');
    const messageText = document.createElement('div');
    messageText.className = 'message-text';
    messageText.innerText = message.message;
    const actionsContainer = document.createElement('div');
    actionsContainer.className = 'actions';
    const action = document.createElement('button');
    action.textContent = message.action.text;
    messageText.style.cssText=`
      color: #000;
      font-family: 'Gilroy-Medium';
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.45px;
    `;

    if (message.type == 'success') {
      action.style.cssText = `
        display: flex;
        align-items: center;
        border-radius: 2.847px;
        border: 2px solid green;
        background: green;
        padding: 8px 30px;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        font-family: 'Inter-Regular';
        font-size: 14px;
        color: #fff;
      `;
    } else if (message.type == 'error') {
      action.style.cssText = `
        display: flex;
        align-items: center;
        border-radius: 2.847px;
        border: 2px solid #e8346c;
        background: rgb(255, 255, 255);
        padding: 8px 30px;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        font-family: 'Inter-Regular';
        font-size: 14px;
        color: #e8346c;
      `;
    }
    action.addEventListener('click', () => {
      this._router.navigate([message.action.route]);
      this.dismissNotice(messageBox);
    })
    actionsContainer.appendChild(action);
    const closeContainer = document.createElement('div');
    closeContainer.className = 'close';
    const closeImg = document.createElement('img');
    closeImg.src = "../../../../assets/icons/popupclose-icon.svg";
    closeImg.addEventListener('click', () => this.dismissNotice(messageBox));
    closeContainer.style.cssText=`
      position: absolute;
      top: 20px;
      right: 20px;
      cursor:pointer;
    `;
    closeContainer.appendChild(closeImg);
    messageContainer.appendChild(messageText);
    if (message.action.hasAction) {
      messageContainer.appendChild(actionsContainer);
    }
    messageContainer.style.cssText = `
      display:flex;
      flex-direction:column;
      gap: 16px
    `;
    messageBox.appendChild(iconContainer);
    messageBox.appendChild(messageContainer);
    messageBox.appendChild(closeContainer);
    messageBox.style.cssText = `
      border-radius: 7px;
      background: #FFF;
      box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.25);
      color: #DB3737;
      font-family: Gilroy-Medium;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.45px;
      display:flex;
      gap: 16px;
      position:relative;
      padding: 24px;
    `;
    messageBoard.appendChild(messageBox);
    setTimeout(() => {
      messageBoard.removeChild(messageBox);
    }, 3000);
  }

  dismissNotice(element: Element) {
    const messageBoard = document.getElementById('message-board') as HTMLElement;
    messageBoard.removeChild(element);
  }
}
