import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CdkService } from '../services/cdk.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private readonly _cdkService: CdkService
  ){}
  intercept(req: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<Response>> {
    //remove cookies
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const cookieName = cookie.split('=')[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    let newreq;
    //add source to request
    if (req.method === 'GET') {
      newreq = req.clone();
    }
    else {
      if (req.body instanceof FormData) {
        newreq = req.clone();
      }
      else {
        newreq = req.clone({ body: { ...req.body, source: 'website', device: this._cdkService.deviceView } });
      }
    }
    return next.handle(newreq);
  }
}
