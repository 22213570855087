import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ReviewActions } from './review.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.store';
import { getProductReviews } from "./review.selectors";
import { API_ROUTES } from 'src/app/core/constants/api.constants';


@Injectable()
export class ReviewEffects {


  constructor(
    private actions$: Actions,
    private _api: ApiHttpService,
    private _store: Store<AppState>
  ) { }


  loadUserReviews$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.loadUserReviews),
        exhaustMap(
          action =>
            this._api.get(API_ROUTES.userReviews).pipe(
              map((data: any) => ReviewActions.loadUserReviewsSuccess({ data: data.data })),
            )
        )
      )
  )

  reloadProductReviews$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.reloadProductReviews),
        exhaustMap(
          action => {
            let id;
            this._store.select(getProductReviews).subscribe(
              data => id = data.productId
            )
            return this._api.get(API_ROUTES.productReviews, { product_id: id }).pipe(
              map((data: any) => ReviewActions.loadProductReviewsSuccess({ data: data.data })),
            )
          }
        )
      )
  )

  loadProductReviews$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.loadProductReviews),
        exhaustMap(
          action =>
            this._api.get(API_ROUTES.productReviews, { product_id: action.product_id }).pipe(
              map((data: any) => ReviewActions.loadProductReviewsSuccess({ data: data.data })),
            )
        )
      )
  )

  writeReview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.writeNewReview, ReviewActions.editReview),
        exhaustMap(
          action =>
            this._api.get(API_ROUTES.writeReview, action.params).pipe(
              map((data: any) => ReviewActions.reloadProductReviews()),
            )
        )
      )
  )

  deleteReview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.deleteReview),
        exhaustMap(
          action =>
            this._api.get(API_ROUTES.deleteReview, { review_id: action.review_id }).pipe(
              map((data: any) => ReviewActions.reloadProductReviews()),
            )
        )
      )
  )

  deleteReviewImage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.deleteReviewImage),
        exhaustMap(
          action =>
            this._api.get(API_ROUTES.deleteReview, { review_image_id: action.review_image_id }).pipe(
              map((data: any) => ReviewActions.reloadProductReviews()),
            )
        )
      )
  )
}
