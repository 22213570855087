import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BrandActions } from './brand.actions';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class BrandEffects {


  constructor(private actions$: Actions, private _api: ApiHttpService) {}


  loadData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BrandActions.loadBrands),
        exhaustMap(
          () =>
            this._api.get(API_ROUTES.brandList).pipe(
              map((data: any) => BrandActions.loadBrandsSuccess({ data: data.data })),
              catchError(err => of(BrandActions.loadBrandsFailure({ error: err })))
            )
        )
      )
  )
}
