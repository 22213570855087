import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.store';
import { reviewFeatureKey } from './review.reducer';


const getReviews = createFeatureSelector<AppState>(reviewFeatureKey);

export const getProductReviews = createSelector(
    getReviews,
    (state: any) => state.productReviews
)

export const getUserReviews = createSelector(
    getReviews,
    (state: any) => state.userReviews
)

export const getProductReviewsStatus = createSelector(
    getReviews,
    (state: any) => state.status
)