<div class="contact_container">
    <div class="insidepage_bannersecstions">
        <img loading="lazy"  src="../../../../assets/images/login_banner.jpg" alt="Buy Makeup Product Online | Foreveryng">
    </div>
    <div class="container">
        <div class="wrap_contact_uss">
            <div class="title">
                Contact Us
            </div>
            <div class="contact_form_box">
                <form>
                    <div class="message_type">
                        <h3>Message Type</h3>
                        <select [(ngModel)]="type" [ngModelOptions]="{standalone: true}">
                            <option value="bug">Bug</option>
                            <option value="feedback">FeedBack</option>
                        </select>
                    </div>
                    <div class="message_content">
                        <h3>Message</h3>
                        <textarea cols="30" rows="10" [(ngModel)]="message" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                    <div class="action">
                        <button (click)="submit()" (keypress)="submit()">Submit</button>
                    </div>
                </form>
            </div>
        </div>
        
    </div>
   
</div>