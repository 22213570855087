import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resolve-dynamic-link',
  templateUrl: './resolve-dynamic-link.component.html',
  styleUrls: ['./resolve-dynamic-link.component.scss'],
})
export class ResolveDynamicLinkComponent implements OnInit {
  private _activeRoute = inject(ActivatedRoute);
  ngOnInit(): void {
    const link = this._activeRoute.snapshot.queryParams['links'];
    window.location.href = link;
  }
}
