import { Component, inject } from '@angular/core';
import { ApiHttpService } from '../../services/api-http.service';
import { API_ROUTES } from '../../constants/api.constants';
import { map } from 'rxjs';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
    private readonly api = inject(ApiHttpService);
    privacyData$ = this.api.get(API_ROUTES.privacy).pipe(
        map((res: any) => res.data)
    );
}
