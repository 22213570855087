import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from '../../services/api-http.service';
import { NoticeService } from '../../services/notice.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.store';
import { getUserStatus } from 'src/app/store/user/user.selectors';
import { Router } from '@angular/router';
import { ErrorNotification } from 'rxjs';
import { API_ROUTES } from '../../constants/api.constants';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  type = 'bug';
  message = '';

  constructor (
    private _api: ApiHttpService,
    private _notice: NoticeService,
    private _store: Store<AppState>,
    private _router: Router
  ) {}
  
  ngOnInit() {
    this._store.select(getUserStatus).subscribe(
      status => {
        if (status == 'loggedOut') {
          this._router.navigate(['/auth']);
        }
      }
    )
  }
  submit() {
    this._api.post(API_ROUTES.contact, { message_type: this.type, message: this.message }).subscribe({
      next: (res: any) => this._notice.addNotice(res.message, 'success'),
      error: (err: ErrorNotification) => this._notice.addNotice(err.error.message, 'error')
    })
  }
}
