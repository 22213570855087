import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductActions } from './product.actions';
import { exhaustMap, map } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';

@Injectable()
export class ProductEffects {

  constructor(
    private actions$: Actions,
    private _api: ApiHttpService
  ) { }

  loadProduct$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProductActions.loadProductDetail),
      exhaustMap(
        action => this._api.get(API_ROUTES.productDetail, { 'product_id': action.product_id }).pipe(
          map((data: any) => ProductActions.loadProductSuccess({ data: data.data })),
        )
      )
    )
  )
}
