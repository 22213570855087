import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { UserActions } from './user.actions';
import { NoticeService } from 'src/app/core/services/notice.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private _api: ApiHttpService,
    private _notice: NoticeService
  ) {}

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      exhaustMap((action) =>
        this._api.get(API_ROUTES.userData).pipe(
          map((data: any) => {
            return UserActions.loadUserSuccess({ data: data.data });
          }),
          catchError((err) => of(UserActions.loadUserFailure({ error: err })))
        )
      )
    )
  );

  editUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.editUserProfile),
      exhaustMap((action) =>
        this._api.post(API_ROUTES.updateUserDetails, action.params).pipe(
          map((data: any) => {
            this._notice.addNotice(data.message);
            return UserActions.loadUser();
          }),
          catchError((err) => {
            this._notice.addNotice(err.error.message, 'error');
            return of();
          })
        )
      )
    )
  );
}
