import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable, map } from 'rxjs';
import { ApiHttpService } from '../../services/api-http.service';
import { API_ROUTES } from '../../constants/api.constants';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FAQsComponent implements OnInit {
  faq$: Observable<{ question: string, answer: string }[]> = EMPTY;
  showAnswer: boolean[] = [false];

  constructor (
    private _api: ApiHttpService
  ) {}

  ngOnInit() {
    this.faq$ = this._api.get(API_ROUTES.getFaqs).pipe(map((v : any) => v.data));
  }
}
