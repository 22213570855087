import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { WishlistActions } from './wishlist.actions';
import { NoticeService } from 'src/app/core/services/notice.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class WishlistEffects {
  constructor(
    private actions$: Actions,
    private _api: ApiHttpService,
    private _notice: NoticeService
  ) { }

  loadWishlist$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WishlistActions.loadWishlist),
        exhaustMap(
          action =>
            this._api.get(API_ROUTES.wishlist).pipe(
              map((data: any) => {
                return WishlistActions.loadWishlistSuccess({ data: data.data });
              })
            )
        )
      )
  )

  addToWishlist$ = createEffect(
    () => this.actions$.pipe(
      ofType(WishlistActions.addToWishlist),
      exhaustMap(
        action => this._api.post(API_ROUTES.addToCart, { attribute_product_id: action.attribute_product_id, quantity: 0, is_cart: 0 }).pipe(
          map((data: any) => {
            const action = { hasAction:true, text:'Go To Wishlist', route:'/profile/wishlist' };
            this._notice.addNotice(data.message, 'success', 5000, action);
            return WishlistActions.loadWishlist();
          }),
          catchError(err => {
            this._notice.addNotice(err.error.message, 'error')
            return of()
          })
        )
      )
    )
  )

  removeFromWishlist$ = createEffect(
    () => this.actions$.pipe(
      ofType(WishlistActions.removeFormWishlist),
      exhaustMap(
        action => this._api.post(API_ROUTES.removeFromWishlist, { product_id: action.product_id }).pipe(
          map(() => {
            const action = { hasAction:false, text:'Go To Wishlist', route:'/profile/wishlist' };
            this._notice.addNotice('Item removed from wishlist', 'success', 5000, action);
            return WishlistActions.loadWishlist();
          })
        )
      )
    )
  )

  notifyMe$ = createEffect(
    () => this.actions$.pipe(
      ofType(WishlistActions.notifyMe),
      exhaustMap(
        action => this._api.post(API_ROUTES.notifyMe, { attribute_product_id: action.attribute_product_id }).pipe(
          map((data: any) => {
            this._notice.addNotice(data.message)
            return WishlistActions.loadWishlist();
          })
        )
      )
    )
  )
}
