import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PostActions = createActionGroup({
  source: 'Post',
  events: {
    'Load Posts': emptyProps(),
    'Load Posts with page': props<{ page: number }>(),
    'Load Posts Success': props<{ data: unknown }>(),
    'Load Posts Failure': props<{ error: unknown }>(),
    'Load Single Post': props<{ post_id: number }>(),
    'Load Single Post Success': props<{ data: any }>(),
    'Toggle Like Post': props<{ post_id: number }>(),
    'Search Post': props<{ params: any }>(),
    'Search Post Success': props<{ data: any }>(),
  }
});
