import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PostActions } from './post.actions';
import { exhaustMap, map } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class PostEffects {


  constructor(
    private actions$: Actions,
    private _api: ApiHttpService
  ) { }

  loadPosts$ = createEffect(
    () => this.actions$.pipe(
      ofType(PostActions.loadPosts),
      exhaustMap(
        () => this._api.get(API_ROUTES.getAllPosts).pipe(
          map( (data: any) => PostActions.loadPostsSuccess({ data: data.data }) )
        )
      )
    )
  )

  loadPostsWithPage$ = createEffect(
    () => this.actions$.pipe(
      ofType(PostActions.loadPostsWithPage),
      exhaustMap(
        (action) => this._api.get(API_ROUTES.getAllPosts, { page: action.page }).pipe(
          map( (data: any) => PostActions.loadPostsSuccess({ data: data.data }) )
        )
      )
    )
  )

  loadSinglePost$ = createEffect(
    () => this.actions$.pipe(
      ofType(PostActions.loadSinglePost),
      exhaustMap(
        (action) => this._api.get(API_ROUTES.getSinglePost, action.post_id).pipe(
          map(
            (data: any) => PostActions.loadSinglePostSuccess({ data: data.data })
          )
        )
      )
    )
  )

  searchPost = createEffect(
    () => this.actions$.pipe(
      ofType(PostActions.searchPost),
      exhaustMap(
        (action) => this._api.get(API_ROUTES.searchPost, action.params).pipe(
          map(
            (data: any) => PostActions.searchPostSuccess({ data: data.data })
          )
        )
      )
    )
  )
}
