import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ReviewActions = createActionGroup({
  source: 'Review',
  events: {
    'Load User Reviews': emptyProps(),
    'Load Product Reviews': props<{ product_id: number }>(),
    'Reload Product Reviews': emptyProps(),
    'Write New Review': props<{ params: any }>(),
    'Edit Review': props<{ params: any}>(),
    'Load User Reviews Success': props<{ data: any }>(),
    'Load Product Reviews Success': props<{ data: any }>(),
    'Delete Review': props<{ review_id: number }>(),
    'Delete Review Image': props<{ review_image_id: number }>(),
  }
});
