<div class="faq_container_secstions">
    <div class="insidepage_bannersecstions" *ngIf="(isHandset$ | async) === false">
        <img loading="lazy"  src="../../../../assets/images/about-us/aboutus_bannerimg.jpg" alt="Buy Makeup Product Online | Foreveryng">
    </div>
    <div class="insidepage_bannersecstions" *ngIf="(isHandset$ | async)">
        <img loading="lazy"  src="../../../../assets/images/about-us/aboutus_bannerimg_mobile.jpg" alt="Buy Makeup Product Online | Foreveryng">
    </div>
    <div class="container">
        <div class="wrap_faqsections">
            <div class="shipping_policy_container main_content_sectionss">
                <h1>About Us</h1>
                <div class="about_us_container">
                    <div class="about_us" style="text-align: center;">
                        <p>
                            Foreveryng is a leading beauty ecommerce platform in Nepal dedicated to providing
                            high-quality products and
                            a
                            seamless shopping experience to customers in Nepal.
                        </p>

                        <p>
                            We understand the importance of self-care and believe that everyone deserves to look and
                            feel their best.
                            Our
                            platform offers a wide range of beauty and skincare products sourced from trusted brands,
                            ensuring that our
                            customers have access to the latest trends and effective solutions for their beauty needs.
                        </p>

                        <p>
                            Foreveryng was founded with a vision to empower people by helping their inner beauty shine
                            through. As
                            Nepal’s
                            largest beauty e-commerce platform, we offer a curated selection of leading international
                            and local beauty
                            brands, along with a commitment to providing high-quality, genuine products and easy returns
                            policy.
                        </p>
                    </div>

                    

                    <div class="mission_vision_section">
                        <div class="mission">
                            <h2>Our Mission</h2>
                            <p>empower individuals to embrace their unique
                                beauty and express themselves through high-quality, inclusive, and innovative beauty
                                products.</p>

                        </div>
                        <div class="vision">
                            <h2> Our Vision</h2>
                            <div class="content">
                                <p> To be the leading global beauty ecommerce platformthat redefines the beauty
                                    landscape. We envision a future where everyone feels empowered to explore and
                                    celebrate their individuality, breaking traditional beautynorms and embracing
                                    diverse standards of
                                    beauty.</p>
                            </div>
                        </div>
                    </div>



                    <div class="values">
                        <h1>
                            Our Values
                        </h1>
                        <div class="grid-box">
                            <div class="box">
                                <img loading="lazy"  src="../../../../assets/images/about-us/1.jpg" alt="Buy Makeup Product Online | Foreveryng">
                            </div>
                            <div class="box">
                                <img loading="lazy"  src="../../../../assets/images/about-us/2.jpg" alt="Buy Makeup Product Online | Foreveryng">
                            </div>
                            <div class="box">
                                <img loading="lazy"  src="../../../../assets/images/about-us/3.jpg" alt="Buy Makeup Product Online | Foreveryng">
                            </div>
                            <div class="box">
                                <img loading="lazy"  src="../../../../assets/images/about-us/4.jpg" alt="Buy Makeup Product Online | Foreveryng">
                            </div>
                            <div class="box">
                                <img loading="lazy"  src="../../../../assets/images/about-us/5.jpg" alt="Buy Makeup Product Online | Foreveryng">
                            </div>
                            <div class="box">
                                <img loading="lazy"  src="../../../../assets/images/about-us/6.jpg" alt="Buy Makeup Products Online | Foreveryng">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>