import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { CategoryActions } from './category.actions';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { API_ROUTES } from 'src/app/core/constants/api.constants';



@Injectable()
export class CategoryEffects {


  constructor(private actions$: Actions, private _api: ApiHttpService) {}

  loadData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.loadCategories),
        exhaustMap(
          () =>
            this._api.get(API_ROUTES.categoriesList).pipe(
              map((data: any) => CategoryActions.loadCategoriesSuccess({ data: data.data })),
              catchError(err => of(CategoryActions.loadCategoriesFailure({ error: err })))
            )
        )
      )
  )
}
