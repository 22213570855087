<div class="container">
    <div class="main-box">
        <div class="success-logo">
            <img loading="lazy"  src="../../../../assets/images/thank-you/tick.png" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        <div class="thankyou-header">
            Thank You!
        </div>
        <div class="wrap_thankyou_blocks">
            <div class="thankyou_txt">
                <div class="subtext">
                    <span>Your order has been successfully placed, and our team is already working hard to ensure its processed swiftly and with the outmost care.</span> 
                    
                    <span>  We Understand the excitement that comes with eagerly waiting for package and we can't wait for you to experience the of Unboxing your new Treasure.</span>  
                    <span>In the meantime, why not explore our app/ website for even more exciting products that could complement your current order.</span>  
                </div>
            </div>

            <div class="confirmed_orders">
                <div class="item-list" *ngIf="orderData$ | async as orderData">
                    <div class="item" *ngFor="let item of orderData.orderedItems">
                        <div class="image">
                            <img loading="lazy"  [src]="item.image" alt="Buy Makeup Product Online | Foreveryng">
                        </div>
                        <div class="product_details">
                            <div class="wrap_prddtls_secs">
                                <h2>{{ item.productName }}</h2>
                                <span>{{ item.discountedPrice * item.quantity | nepaliCurrency }}</span>
                            </div>
                            <div class="prod_qty_see">
                                QTY : {{ item.quantity }}
                            </div>
                            <a class="track_yurs_ordrs" [routerLink]="['/orders/order', orderData.id]">
                                <button>Track Your Order</button>
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="logos">
            <div class="social">
                <div class="insta">
                    <img loading="lazy"  src="../../../../assets/images/footer/instagram.svg" alt="Buy Makeup Product Online | Foreveryng">
                </div>
                <div class="fb">
                    <img loading="lazy"  src="../../../../assets/images/footer/facebook.svg" alt="Buy Makeup Product Online | Foreveryng">
                </div>
            </div>
        </div>

        
    </div>
</div>