<div class="not_found_container">
    <div class="container">
        <div class="notfound_image">
            <img loading="lazy"  src="../../../../assets/images/404notfound.gif" alt="Buy Makeup Product Online | Foreveryng">
            <a [routerLink]="['/']">
                <button>
                    GO TO HOMEPAGE
                </button>
            </a>
        </div>
    </div>
</div>