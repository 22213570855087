import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ProductActions = createActionGroup({
  source: 'Product',
  events: {
    'Load Product Detail': props<{ product_id: number }>(),
    'Load Product Success': props<{ data: any }>(),
    'Dump Product Detail': emptyProps(),
  }
});
