import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsAndConditionsComponent } from './core/components/terms-and-conditions/terms-and-conditions.component';
import { ContactComponent } from './core/components/contact/contact.component';
import { FAQsComponent } from './core/components/faqs/faqs.component';
import { PrivacyComponent } from './core/components/privacy/privacy.component';
import { ThankYouComponent } from './core/components/thank-you/thank-you.component';
import { AboutUsComponent } from './core/components/about-us/about-us.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { ShippingPolicyComponent } from './core/components/shipping-policy/shipping-policy.component';
import { ResolveDynamicLinkComponent } from './core/components/resolve-dynamic-link/resolve-dynamic-link.component';

const routes: Routes = [
  //Eager Loaded
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    title: 'Terms and Conditions | Foreveryng',
  },
  {
    path: 'FAQs',
    component: FAQsComponent,
    title: 'Frequently asked Questions | Foreveryng',
  },
  {
    path: 'privacy-policy',
    component: PrivacyComponent,
    title: 'Privacy Policy | Foreveryng',
  },
  {
    path: 'privacy',
    redirectTo: 'privacy-policy',
    pathMatch: 'full',
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    title: 'About Us | Foreveryng',
  },
  {
    path: 'contact-us',
    component: ContactComponent,
    title: 'Contact Us | Foreveryng',
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    title: "Nepal's Largest Beauty Ecommerce | Foreveryng",
  },
  {
    path: 'shipping-policy',
    component: ShippingPolicyComponent,
    title: 'Shipping Policy | Foreveryng',
  },
  {
    path: 'thank-you/:order_id',
    component: ThankYouComponent,
    title: "Nepal's Largest Beauty Ecommerce | Foreveryng",
  },
  {
    path: 'resolveDynamicLink',
    component: ResolveDynamicLinkComponent,
    title: 'Redirecting Dynamic Link',
  },

  //lazy loaded
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'catalog',
    loadChildren: () =>
      import('./features/catalog/catalog.module').then((m) => m.CatalogModule),
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./features/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'reviews',
    loadChildren: () =>
      import('./features/reviews/reviews.module').then((m) => m.ReviewsModule),
    title: 'User Reviews',
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./features/cart/cart.module').then((m) => m.CartModule),
    title: 'Cart | Foreveryng',
  },
  {
    path: 'address',
    loadChildren: () =>
      import('./features/address/address.module').then((m) => m.AddressModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./features/orders/orders.module').then((m) => m.OrdersModule),
    title: 'Orders | Foreveryng',
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./features/profile/profile.module').then((m) => m.ProfileModule),
    title: "Nepal's Largest Beauty Ecommerce | Foreveryng",
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./features/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'filter',
    loadChildren: () =>
      import('./features/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'gift-card',
    loadChildren: () =>
      import('./features/gift-cards/gift-cards.module').then(
        (m) => m.GiftCardsModule
      ),
  },
  {
    path: ':type/:slug/:id',
    loadChildren: () =>
      import('./features/search/search.module').then((m) => m.SearchModule),
  },
  // {
  //   path: 'post',
  //   loadChildren: () =>
  //     import('./features/posts/posts.module').then((m) => m.PostsModule),
  // },
  {
    path: 'campaign',
    loadChildren: () =>
      import('./features/campaign/campaign.module').then(
        (m) => m.CampaignModule
      ),
  },
  {
    path: 'campaign_ui',
    loadChildren: () =>
      import('./features/campaign/campaign.module').then(
        (m) => m.CampaignModule
      ),
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('./features/membership/membership.module').then(
        (m) => m.MembershipModule
      ),
  },
  // {
  //   path: 'influencer',
  //   loadChildren: () =>
  //     import('./features/influencer/influencer.module').then(
  //       (m) => m.InfluencerModule
  //     ),
  // },
  {
    path: 'spin-and-win',
    loadChildren: () =>
      import('./features/spin-and-win/spin-and-win.module').then(
        (m) => m.SpinAndWinModule
      ),
  },

  // redirect non matching routes
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
